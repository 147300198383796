<template>
  <div class="container-fluid">
    <div class="sidebar">
      <div v-if="user.company_logo" class="my-3 text-center">
        <img :src="user.company_logo" alt="Logo" class="img-fluid w-50" />
      </div>
      <accordion-views
        v-if="
          userMarcosLegalesEspecificos.length &&
          selectedView !== 'acciones-correctivas'
        "
        :mles="this.userMarcosLegalesEspecificos"
        :viewName="selectedView"
        :ambitos="ambitos"
        :aspectos="aspectos"
        @showMLEs="showMLEs"
      />
    </div>
    <main>
      <div
        v-if="
          userMarcosLegalesEspecificos.length &&
          user.marcos_legales_especificos_count >
            userMarcosLegalesEspecificos.length
        "
        class="alert alert-warning"
        role="alert"
      >
        Has excedido el límite de marcos legales, por lo cuál solo tendrás
        disponibles 100.
      </div>
      <ul v-if="userMarcosLegalesEspecificos.length" class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="selectedView === 'ambitos' ? 'active' : ''"
            href="#"
            @click.prevent="
              (selectedView = 'ambitos'), showMLEs({ MLGKey: '', MLG: [] })
            "
            >Ámbito</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="selectedView === 'aspectos' ? 'active' : ''"
            href="#"
            @click.prevent="
              (selectedView = 'aspectos'), showMLEs({ MLGKey: '', MLG: [] })
            "
            >Aspecto</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="selectedView === 'resumen' ? 'active' : ''"
            href="#"
            @click.prevent="
              (selectedView = 'resumen'), showMLEs({ MLGKey: '', MLG: [] })
            "
            >Resumen</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="selectedView === 'acciones-correctivas' ? 'active' : ''"
            href="#"
            @click.prevent="selectedView = 'acciones-correctivas'"
          >
            Acciones correctivas
          </a>
        </li>
      </ul>
      <p v-if="!userMarcosLegalesEspecificos.length && !loadingUserMLEs">
        Tu matriz está vacía, puedes seleccionar tus marcos legales
        <router-link to="/biblioteca-legal">aquí</router-link>
      </p>
      <div
        v-if="Object.keys(selectedMLG).length && !selectedMLE.name"
        class="container-fluid mt-4"
      >
        <c-card :title="Object.keys(selectedMLG)[0] || ' '">
          <template v-slot:header-right>
            <div v-if="selectedView !== 'acciones-correctivas'">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="Sí"
                  v-model="cumpleFilters['Sí']"
                />
                <label class="form-check-label" for="inlineCheckbox1"
                  >Cumple</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  v-model="cumpleFilters['No']"
                />
                <label class="form-check-label" for="inlineCheckbox2"
                  >No cumple</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                  v-model="cumpleFilters['NA']"
                />
                <label class="form-check-label" for="inlineCheckbox3"
                  >No aplica</label
                >
              </div>
            </div>
            <div v-else>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="abierta"
                  v-model="statusFilters['Abierta']"
                />
                <label class="form-check-label" for="abierta">Abierta</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="cerrada"
                  v-model="statusFilters['Cerrada']"
                />
                <label class="form-check-label" for="cerrada">Cerrada</label>
              </div>
            </div>
          </template>
          <MLEs-table
            @visualizar="showMLE"
            :MLG="selectedMLG"
            :filters="
              selectedView === 'acciones-correctivas'
                ? statusFilters
                : cumpleFilters
            "
            :viewName="selectedView"
          />
        </c-card>
      </div>
      <div v-if="selectedMLE.name" class="container mt-4">
        <a
          href="javascript:void(0)"
          @click="
            showMLEs({
              MLGKey: Object.keys(selectedMLG)[0],
              MLG: selectedMLG[Object.keys(selectedMLG)[0]],
            })
          "
        >
          {{ Object.keys(selectedMLG)[0] }}</a
        >
        <MLE-form
          :mle="selectedMLE"
          :nextMLE="nextMLE"
          :prevMLE="prevMLE"
          @change-mle="changeMLE"
        />
      </div>
    </main>
  </div>
</template>
<script>
import { all as allAmbitos } from "@/services/ambitos";
import { all as allAspectos } from "@/services/aspectos";
import { showNotification } from "../services/notification";
import { marcosLegalesEspecificos as userMarcosLegalesEspecificos } from "@/services/users";
import { findOrder } from "@/services/conekta";
import CCard from "@/components/CCard.vue";
import MLEForm from "@/components/MatrizLegal/MLEForm";
import MLEsTable from "@/components/MatrizLegal/MLEsTable";
import AccordionViews from "@/components/MatrizLegal/AccordionViews";
import { toolFiles } from "@/services/users";
import { authUser } from "@/services/auth";

export default {
  components: {
    CCard,
    MLEForm,
    MLEsTable,
    AccordionViews,
  },
  data() {
    return {
      user: authUser(),
      selectedMLG: {},
      ambitos: [],
      aspectos: [],
      userMarcosLegalesEspecificos: [],
      loadingUserMLEs: true,
      ambitosUser: {},
      selectedMLE: {},
      nextMLE: null,
      prevMLE: null,
      cumpleFilters: {
        ["Sí"]: true,
        No: true,
        NA: true,
      },
      statusFilters: {
        Abierta: true,
        Cerrada: true,
      },
      selectedView: "ambitos",
    };
  },
  mounted() {
    this.getAmbitos();
    this.getAspectos();
    this.getPurchasedTool();
  },
  watch: {
    selectedView(view) {
      // this is an exception
      if (view === "acciones-correctivas") {
        this.showMLEs({
          MLGKey: "Marcos legales específicos",
          MLG: this.userMarcosLegalesEspecificos.filter(
            (mle) => mle.pivot.action_status
          ),
        });
      }
    },
  },
  methods: {
    getAmbitos() {
      allAmbitos()
        .then((response) => {
          this.ambitos = response.data;
          this.getUserMarcosLegalesEspecificos();
        })
        .catch(() => {
          showNotification(500);
        });
    },

    getAspectos() {
      allAspectos()
        .then((response) => {
          this.aspectos = response.data;
        })
        .catch(() => {
          showNotification(500);
        });
    },

    getUserMarcosLegalesEspecificos() {
      userMarcosLegalesEspecificos()
        .then((response) => {
          this.userMarcosLegalesEspecificos = response.data.sort((mle, mle2) =>
            mle.marco_legal_general_version.marco_legal_general.name >
            mle2.marco_legal_general_version.marco_legal_general.name
              ? 1
              : -1
          );
          this.loadingUserMLEs = false;
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error obteniendo tu matriz legal"
          );
        });
    },

    showMLEs({ MLGKey, MLG }) {
      this.selectedMLE = {};
      const object = {};
      object[MLGKey] = MLG;
      this.selectedMLG = object;
    },

    showMLE(marco) {
      this.selectedMLE = marco;
      this.setNextAndPrevMLE();
    },

    changeMLE(position) {
      position === "prev"
        ? (this.selectedMLE = this.prevMLE)
        : (this.selectedMLE = this.nextMLE);
      this.setNextAndPrevMLE();
    },

    setNextAndPrevMLE() {
      const mles = this.selectedMLG[Object.keys(this.selectedMLG)[0]];
      const currentMLEIndex = mles.findIndex(
        (mle) => mle.id === this.selectedMLE.id
      );
      currentMLEIndex > 0
        ? (this.prevMLE = mles[currentMLEIndex - 1])
        : (this.prevMLE = null);
      currentMLEIndex < mles.length - 1
        ? (this.nextMLE = mles[currentMLEIndex + 1])
        : (this.nextMLE = null);
    },

    getPurchasedTool() {
      const paid = this.$route.query.payment_status;
      if (paid !== "paid") {
        return;
      }
      const order = this.$route.query.order_id;
      findOrder(order)
        .then((response) => {
          if (response.data.payment_status === "paid") {
            const toolId = response.data.line_items.data[0].sku;
            this.getToolFiles(toolId);
          }
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "No pudimos descargar la herramienta"
          );
        });
    },

    getToolFiles(toolId) {
      toolFiles(toolId)
        .then((response) => {
          const files = response.data;
          files.forEach((file) => {
            window.open(file.document);
          });
          const query = Object.assign({}, this.$route.query);
          delete query.payment_status;
          this.$router.replace({ query });
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "No pudimos descargar las herramientas. " + error
          );
        });
    },
  },
};
</script>
<style lang="sass" scoped>
.sidebar
  width: 300px
  position: fixed
  max-height: calc(100vh - 86px)
  overflow: auto
main
  margin-left: 300px
  width: calc(100% - 300px)
</style>
