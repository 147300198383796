<template>
  <div>
    <div class="accordion accordion-flush">
      <div
        class="accordion-item"
        v-for="item in this.selectedArrayView"
        :key="item.id"
      >
        <h2 class="accordion-header" :id="`flush-heading-view${item.id}`">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#flush-collapse-view${item.id}`"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            {{ item.name }} ({{ item.totalMLEs }})
          </button>
        </h2>
        <div
          :id="`flush-collapse-view${item.id}`"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <ul>
              <li v-for="(MLG, MLGKey) in item.mlgs" :key="MLGKey">
                <a
                  href="javascript:void(0)"
                  @click="$emit('showMLEs', { MLGKey: MLGKey, MLG: MLG })"
                  >{{ MLGKey }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <h6 class="m-3" v-if="viewName === 'resumen'">
      Porcentaje de cumplimiento:
      <span class="text-info fs-4 text">
        {{
          (
            (resume[0].totalMLEs /
              (resume[0].totalMLEs + resume[1].totalMLEs)) *
            100
          ).toFixed(2) || 0
        }}
      </span>
    </h6>
  </div>
</template>
<script>
export default {
  props: {
    mles: {
      required: true,
      default: [],
    },
    viewName: {
      required: true,
      default: "ambitos",
    },
    ambitos: {
      required: true,
      default: [],
    },
    aspectos: {
      required: true,
      default: [],
    },
  },
  data() {
    return {
      ambitosCopy: [...this.ambitos],
      aspectosCopy: [...this.aspectos],
      resume: [
        { id: "Sí", name: "Cumple" },
        { id: "No", name: "No cumple" },
        { id: "NA", name: "No Aplica" },
      ],
      selectedArrayView: this.ambitosCopy,
    };
  },
  mounted() {
    this.sortByView();
    this.selectViewData();
  },
  watch: {
    viewName() {
      this.selectViewData();
    },
  },
  methods: {
    selectViewData() {
      switch (this.viewName) {
        case "ambitos":
          this.selectedArrayView = this.ambitosCopy;
          break;
        case "aspectos":
          this.selectedArrayView = this.aspectosCopy;
          break;
        case "resumen":
          this.selectedArrayView = this.resume;
      }
    },
    sortByView() {
      this.orderMLEs();
    },
    // arrayView are aspectos or ambitos, key is the related field
    addMLEToView(mle, arrayView, key) {
      let mleRelatedKey = mle[key];

      // pivot.cumple is an exception
      if (key === "pivot.cumple") {
        mleRelatedKey = mle.pivot.cumple;
      }

      arrayView.forEach((item) => {
        item.totalMLEs = item.totalMLEs || 0;
        if (item.id === mleRelatedKey) {
          item.totalMLEs++;
          if (!item.mlgs) {
            item.mlgs = {};
          }
          item.mlgs[mle.marco_legal_general_version.marco_legal_general.name]
            ? item.mlgs[
                mle.marco_legal_general_version.marco_legal_general.name
              ].push(mle)
            : (item.mlgs[
                mle.marco_legal_general_version.marco_legal_general.name
              ] = [mle]);
        }
      });
    },
    orderMLEs() {
      // prevents duplicity
      this.ambitosCopy.forEach((item) => {
        item.totalMLEs = 0;
        item.mlgs = {};
      });
      this.aspectosCopy.forEach((item) => {
        item.totalMLEs = 0;
        item.mlgs = {};
      });
      this.resume.forEach((item) => {
        item.totalMLEs = 0;
        item.mlgs = {};
      });

      this.mles.forEach((mle) => {
        this.addMLEToView(mle, this.ambitosCopy, "ambito_id");
        this.addMLEToView(mle, this.aspectosCopy, "aspecto_id");
        this.addMLEToView(mle, this.resume, "pivot.cumple");
      });
    },
  },
};
</script>
