<template>
  <div>
    <hr />
    <h3 class="py-3">Herramientas</h3>
    <!-- Tools modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ selectedTool.name }}
            </h5>
            <button
              id="close-tools-modal"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                class="col-lg-6 mt-3"
                v-for="image in selectedTool.images_tools"
                :key="image.id"
              >
                <img
                  :src="image.image_link"
                  class="img-fluid"
                  alt="Imagen de herramienta"
                />
              </div>
            </div>
            <div class="mt-3">
              <h6>{{ selectedTool.name }}</h6>
              <h6>Categoría: {{ selectedTool.category }}</h6>
              <p class="mt-3">Descripción: {{ selectedTool.description }}</p>
              <p>Contenido: {{ selectedTool.content }}</p>
            </div>
            <c-primary-button
              @click="getTool(selectedTool)"
              class="w-100 mt-4"
              :disabled="loadingCheckout"
            >
              {{
                selectedTool.free
                  ? "Obtener herramienta gratuita"
                  : "Comprar herramienta"
              }}
            </c-primary-button>
          </div>
        </div>
      </div>
    </div>
    <!--  Tool images  -->
    <div class="row">
      <div class="col-md-4 mt-3" v-for="tool in tools" :key="tool.id">
        <a
          href="javascript:void(0)"
          @click.prevent="showImages(tool)"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <img
            v-if="tool.main_image_link"
            class="img-fluid"
            :src="tool.main_image_link"
            alt="Imagen principal de la herramienta"
          />
          <span v-else> Sin imagen </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { toolFiles } from "@/services/users";
import { showNotification } from "@/services/notification";

export default {
  props: ["tools"],
  components: {
    CPrimaryButton,
  },
  data() {
    return {
      selectedTool: {},
      loadingCheckout: false,
    };
  },
  methods: {
    showImages(tool) {
      this.selectedTool = tool;
    },

    getTool(tool) {
      tool.free ? this.getFreeTool() : this.buyTool();
    },

    getFreeTool() {
      toolFiles(this.selectedTool.id)
        .then((response) => {
          const files = response.data;
          files.forEach((file) => {
            window.open(file.document);
          });
          document.querySelector("#close-tools-modal").click();
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "No pudimos descargar las herramientas. " + error
          );
        });
    },

    buyTool() {
      window.open(this.selectedTool.link);
    },
  },
};
</script>
