<template>
  <div class="table-responsive">
    <show-paid-or-unpaid-data :paidInfo="true">
      <div class="d-flex justify-content-end">
        <json-excel :data="filteredMLEs" :fields="JSONFields">
          <c-primary-button> Descargar </c-primary-button>
        </json-excel>
      </div>
    </show-paid-or-unpaid-data>
    <table class="table table-striped table-responsive">
      <thead>
        <tr>
          <th v-if="viewName === 'acciones-correctivas'">
            Autoridad reguladora
          </th>
          <th v-if="viewName === 'acciones-correctivas'">
            Marco legal general
          </th>
          <th>Marco legal específico</th>
          <th>Requerimiento</th>
          <th v-if="viewName === 'acciones-correctivas'">
            Última fecha de realización
          </th>
          <th v-if="viewName === 'acciones-correctivas'">Próxima fecha</th>
          <th v-if="viewName === 'acciones-correctivas'">Acción correctiva</th>
          <th v-if="viewName === 'acciones-correctivas'">
            Responable de la acción correctiva
          </th>
          <th v-if="viewName === 'acciones-correctivas'">
            Status de la acción correctiva
          </th>
          <th v-if="viewName === 'acciones-correctivas'">
            Fecha de compromiso de cierre
          </th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="marco in filteredMLEs" :key="marco.id">
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.autoridad_reguladora }}
          </td>
          <template v-if="viewName === 'acciones-correctivas'">
            <td v-for="(value, key) in MLG" :key="key">{{ key }}</td>
          </template>
          <td>{{ marco.name }}</td>
          <td>{{ marco.requerimiento }}</td>
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.pivot.last_date }}
          </td>
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.pivot.next_date }}
          </td>
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.pivot.action }}
          </td>
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.pivot.owner }}
          </td>
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.pivot.action_status }}
          </td>
          <td v-if="viewName === 'acciones-correctivas'">
            {{ marco.pivot.due_date }}
          </td>
          <td class="text-nowrap">
            <a
              @click="$emit('visualizar', marco)"
              class="btn btn-secondary btn-sm ms-2 d-inline-block"
              href="javascript:void(0)"
              title="Visualizar"
              ><i
                class="fa-solid fa-eye"
                :class="marco.tools_count > 0 ? 'text-info' : ''"
              ></i>
            </a>
            <a
              class="btn btn-secondary btn-sm ms-2 d-inline-block"
              :class="getCumpleButtonClass(marco.pivot.cumple)"
              href="javascript:void(0)"
              title="Cumple"
            >
              <i
                v-if="marco.pivot.cumple === 'Sí'"
                class="fa-solid fa-check"
              ></i>
              <i
                v-else-if="marco.pivot.cumple === 'No'"
                class="fa-solid fa-x"
              ></i>
              <i
                v-else-if="marco.pivot.cumple === 'NA'"
                class="fa-solid fa-ban"
              ></i>
            </a>
          </td>
        </tr>
        <!-- </template>-->
      </tbody>
    </table>
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel3";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import {
  accionesCorrectivasFields,
  ambitoFields,
  aspectoFields,
  resumenFields,
} from "@/shared/JSONExportFields";
import ShowPaidOrUnpaidData from "@/components/showPaidOrUnpaidData.vue";

export default {
  props: {
    MLG: {
      default: {},
    },
    viewName: {
      required: true,
      default: "ambitos",
    },
    filters: {
      required: true,
      default: {
        ["Sí"]: true,
        No: true,
        NA: true,
      },
    },
  },
  components: {
    ShowPaidOrUnpaidData,
    JsonExcel,
    CPrimaryButton,
  },
  data() {
    return {
      filteredMLEs: [],
    };
  },
  mounted() {
    this.filterMLEs();
  },
  computed: {
    JSONFields() {
      switch (this.viewName) {
        case "ambitos":
          return ambitoFields;
        case "aspectos":
          return aspectoFields;
        case "acciones-correctivas":
          return accionesCorrectivasFields;
        case "resumen":
          return resumenFields;
        default:
          return {};
      }
    },
  },
  watch: {
    MLG() {
      this.filterMLEs();
    },
    filters: {
      deep: true,
      handler() {
        this.viewName === "acciones-correctivas"
          ? this.filterMLEs("action_status")
          : this.filterMLEs();
      },
    },
  },
  methods: {
    getCumpleButtonClass(cumple) {
      let buttonClass = "btn-secondary";
      if (cumple === "Sí") {
        buttonClass = "btn-success";
      } else if (cumple === "No") {
        buttonClass = "btn-danger";
      }
      return buttonClass;
    },
    filterMLEs(field = "cumple") {
      const filtered = [];
      const filters = this.filtersToArray();
      for (let key in this.MLG) {
        this.MLG[key].forEach((mle) => {
          if (filters.includes(mle.pivot[field])) {
            filtered.push(mle);
          }
        });
      }
      this.filteredMLEs = filtered;
    },
    filtersToArray() {
      const arrayFilters = [];
      for (let key in this.filters) {
        if (this.filters[key] === true) {
          arrayFilters.push(key);
        }
      }
      return arrayFilters;
    },
  },
};
</script>
