<template>
  <div v-if="!loading">
    <div v-if="(paidInfo && plan.is_active) || (!paidInfo && !plan.is_active)">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { subscriptions } from "@/services/users";
export default {
  props: {
    paidInfo: {
      default: true,
    },
  },
  data() {
    return {
      plan: { is_active: true },
      loading: true,
    };
  },
  mounted() {
    this.getSubscriptions();
  },
  methods: {
    getSubscriptions() {
      subscriptions()
        .then((response) => {
          this.plan = response.data;
          this.loading = false;
        })
        .catch(() => alert("No pudimos cargar tu plan"));
    },
  },
};
</script>
