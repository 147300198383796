<template>
  <div>
    <div
      class="p-3 d-flex justify-content-between align-items-center show-options"
    >
      <div>
        <button
          @click="$emit('change-mle', 'prev')"
          class="btn btn-secondary d-inline-block"
          :disabled="!prevMLE"
        >
          &lt;
        </button>
        <button
          @click="$emit('change-mle', 'next')"
          class="btn btn-secondary d-inline-block ms-2"
          :disabled="!nextMLE"
        >
          >
        </button>
      </div>
      <div class="d-flex">
        <div class="form-check form-switch">
          <label class="form-check-label" for="flexSwitchCheckDefault"
            >Mostrar herramientas</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            @change="showMLETools()"
            v-model="showTools"
          />
        </div>
        <div class="form-check form-switch ps-5">
          <label class="form-check-label" for="actions"
            >Mostrar acciones correctivas</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="actions"
            v-model="showActions"
          />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="mb-3">
            <label for="ordenamiento" class="form-label">Requerimiento:</label>
            <textarea
              class="form-control"
              id="requerimiento"
              disabled
              rows="3"
              v-model="selectedMLE.requerimiento"
            />
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="mb-3">
                <label for="semaforo" class="form-label">Semáforo:</label>
                <input
                  v-model="selectedMLE.semaforo"
                  class="form-control"
                  :class="getSemaforoColorClass(selectedMLE.semaforo)"
                  id="semaforo"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label for="cumple" class="form-label">Cumple:</label>
                <select
                  v-model="selectedMLE.pivot.cumple"
                  id="cumple"
                  class="form-select"
                  @change="updateCumpleSelectedMLE()"
                >
                  <option value="Sí">Sí</option>
                  <option value="No">No</option>
                  <option value="NA">NA</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label for="aspecto" class="form-label">Aspecto:</label>
                <input
                  v-model="selectedMLE.aspecto"
                  class="form-control"
                  id="aspecto"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label for="name" class="form-label"
                  >Marco legal específico:</label
                >
                <input
                  v-model="selectedMLE.name"
                  class="form-control"
                  id="name"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row"></div>
          <!-- Palabras clave -->
          <div class="mb-3">
            <label for="keywords" class="form-label">Palabras clave:</label>
            <input
              v-model="selectedMLE.palabras_clave"
              class="form-control"
              id="keywords"
              disabled
            />
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="mb-3">
                <label for="ordenamiento" class="form-label"
                  >Tipo de ordenamiento:</label
                >
                <input
                  class="form-control"
                  id="ordenamiento"
                  disabled
                  :value="selectedMLE.ordenamiento"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="mlg" class="form-label">Marco legal general:</label>
                <input
                  class="form-control"
                  id="mlg"
                  disabled
                  :value="
                    selectedMLE?.marco_legal_general_version
                      ?.marco_legal_general?.name
                  "
                  :title="
                    selectedMLE?.marco_legal_general_version
                      ?.marco_legal_general?.name
                  "
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label for="ambito" class="form-label">Ámbito:</label>
                <input
                  class="form-control"
                  id="ambito"
                  disabled
                  :value="selectedMLE.ambito"
                />
              </div>
            </div>
          </div>
          <!-- link oficial -->
          <div class="row">
            <div class="col-md-8">
              <div class="mb-3 input-group">
                <label for="link" class="form-label">Link oficial:</label>
                <input
                  class="form-control"
                  id="link"
                  disabled
                  :value="selectedMLE.link_oficial"
                />
                <a
                  class="
                    btn btn-secondary btn-sm
                    d-flex
                    align-items-center
                    input-group-text
                  "
                  :href="selectedMLE.link_oficial"
                  target="_blank"
                  title="Visualizar"
                >
                  <i class="fas fa-globe"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3">
                <label for="fecha_publicacion_oficial" class="form-label"
                  >Fecha de publicación oficial:</label
                >
                <input
                  class="form-control"
                  id="fecha_publicacion_oficial"
                  disabled
                  :value="selectedMLE.fecha_publicacion_oficial"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label for="autoridad_reguladora" class="form-label"
                  >Autoridad reguladora:</label
                >
                <input
                  class="form-control"
                  id="autoridad_reguladora"
                  disabled
                  :value="selectedMLE.autoridad_reguladora"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label for="tipo_de_verificación" class="form-label"
                  >Tipo de verificación:</label
                >
                <input
                  class="form-control"
                  id="tipo_de_verificación"
                  disabled
                  :value="selectedMLE.verificacion"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3">
                <label
                  for="requerido_programacion_de_analisis_de_estudio_y_o_reporte"
                  class="form-label"
                  >¿Requiere programación de análisis, estudio y/o
                  reporte?:</label
                >
                <input
                  class="form-control"
                  id="requerido_programacion_de_analisis_de_estudio_y_o_reporte"
                  disabled
                  :value="
                    selectedMLE.requerido_programacion_de_analisis_de_estudio_y_o_reporte
                  "
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label for="frequency" class="form-label">Frecuencia:</label>
                <input
                  class="form-control"
                  id="frequency"
                  disabled
                  :value="selectedMLE.frequency"
                />
              </div>
            </div>
          </div>
        </form>
        <actions-form v-if="showActions" :mle="selectedMLE" />
        <tools :tools="selectedMLETools" v-if="showTools" />
      </div>
    </div>
  </div>
</template>
<script>
import { updateMarcoLegalEspecificoActions } from "@/services/users";
import { tools } from "@/services/marcos_legales_especificos";
import ActionsForm from "@/components/MatrizLegal/ActionsForm";
import Tools from "@/components/MatrizLegal/Tools";
import { showNotification } from "../../services/notification";

export default {
  props: {
    mle: {
      required: true,
      default: {},
    },
    nextMLE: {
      required: true,
      default: null,
    },
    prevMLE: {
      required: true,
      default: null,
    },
  },
  components: {
    ActionsForm,
    Tools,
  },
  data() {
    return {
      selectedMLETools: [],
      showActions: false,
      showTools: false,
    };
  },
  computed: {
    selectedMLE() {
      return this.mle;
    },
  },
  watch: {
    mle() {
      this.showMLETools();
    },
  },
  methods: {
    updateCumpleSelectedMLE() {
      const data = { cumple: this.selectedMLE.pivot.cumple };
      updateMarcoLegalEspecificoActions(this.selectedMLE.id, data).then(() =>
        showNotification(
          200,
          "success",
          "Actualizamos el status de cumplimiento."
        )
      );
    },

    showMLETools() {
      this.selectedMLETools = [];
      if (this.showTools) {
        tools(this.selectedMLE.id)
          .then((response) => (this.selectedMLETools = response.data))
          .catch((error) => {
            showNotification(
              error.status,
              "danger",
              "Tuvimos un error obteniendo las herramientas"
            );
          });
      }
    },

    getSemaforoColorClass(semaforo) {
      let semaforoClass = "";
      switch (semaforo) {
        case "Vigente":
          semaforoClass = "green";
          break;
        case "Obsoleto":
          semaforoClass = "red";
          break;
        case "Proxima a entrar en vigor":
          semaforoClass = "orange";
          break;
        case "En proyecto":
          semaforoClass = "blue";
          break;
        case "En revision":
          semaforoClass = "grey";
          break;
        default:
          semaforoClass = " ";
      }

      return semaforoClass;
    },
  },
};
</script>
<style lang="sass" scoped>
div[class*="col-"]
  display: flex
  align-items: flex-end
  div
    width: 100%
.show-options
  background-color: #f6f6f6
  position: sticky
  top: 0
  z-index: 99
// semaforo colors
.green
  color: #8be38b
.red
  color: #ea5a5a
.orange
  color: orange
.blue
  color: #3949ab
.grey
  color: #6c6c6c
</style>
