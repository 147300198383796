<template>
  <form @submit.prevent="saveActions()">
    <h3 class="py-3">Acciones correctivas</h3>
    <div class="row">
      <div class="col-md-4">
        <div class="mb-3">
          <label for="last_time_done" class="form-label"
            >Última fecha de realización:</label
          >
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="lastDateEnabled"
                id="lastDate"
                :value="true"
                v-model="lastDateEnabled"
              />
              <label class="form-check-label" for="lastDate">Fecha</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="lastDateEnabled"
                id="lastDateNA"
                :value="false"
                v-model="lastDateEnabled"
              />
              <label class="form-check-label" for="lastDateNA">NA</label>
            </div>
          </div>
          <input
            v-if="lastDateEnabled"
            class="form-control"
            type="date"
            id="last_time_done"
            v-model="actions.last_date"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="next_date" class="form-label">Próxima fecha:</label>
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="nextDateEnabled"
                id="nextDate"
                :value="true"
                v-model="nextDateEnabled"
              />
              <label class="form-check-label" for="nextDate">Fecha</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="nextDateEnabled"
                id="nextDateNA"
                :value="false"
                v-model="nextDateEnabled"
              />
              <label class="form-check-label" for="nextDateNA">NA</label>
            </div>
          </div>
          <input
            v-if="nextDateEnabled"
            class="form-control"
            type="date"
            id="next_date"
            v-model="actions.next_date"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="end_date" class="form-label"
            >Fecha de compromiso del cierre:</label
          >
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="dueDateEnabled"
                id="dueDate"
                :value="true"
                v-model="dueDateEnabled"
              />
              <label class="form-check-label" for="dueDate">Fecha</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="dueDateEnabled"
                id="dueDateNA"
                :value="false"
                v-model="dueDateEnabled"
              />
              <label class="form-check-label" for="dueDateNA">NA</label>
            </div>
          </div>
          <input
            v-if="dueDateEnabled"
            class="form-control"
            type="date"
            id="end_date"
            v-model="actions.due_date"
          />
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="action" class="form-label"
        >Acción correctiva (en caso de aplicar):</label
      >
      <textarea
        rows="3"
        class="form-control"
        id="action"
        v-model="actions.action"
      >
      </textarea>
    </div>
    <div class="mb-3">
      <label for="responsible" class="form-label"
        >Responsable de la acción correctiva:</label
      >
      <input class="form-control" id="responsible" v-model="actions.owner" />
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="action_status" class="form-label"
            >Estatus de la acción correctiva:</label
          >
          <select
            v-model="actions.action_status"
            id="action_status"
            class="form-select"
          >
            <option value="Abierta">Abierta</option>
            <option value="Cerrada">Cerrada</option>
            <option value="N/A">N/A</option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="comments" class="form-label">Comentarios:</label>
      <textarea
        rows="3"
        class="form-control"
        id="comments"
        v-model="actions.comments"
      />
    </div>
    <div class="text-end">
      <c-primary-button :disabled="loading"> Guardar </c-primary-button>
    </div>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { updateMarcoLegalEspecificoActions } from "@/services/users";
import { showNotification } from "@/services/notification";
export default {
  components: {
    CPrimaryButton,
  },
  props: {
    mle: {
      default: {},
    },
  },
  data() {
    return {
      loading: false,
      actions: this.mle.pivot,
      lastDateEnabled: true,
      nextDateEnabled: true,
      dueDateEnabled: true,
    };
  },
  watch: {
    mle() {
      this.actions = this.mle.pivot;
    },
  },
  methods: {
    saveActions() {
      this.loading = true;
      this.cleanOptionalDates();
      updateMarcoLegalEspecificoActions(this.mle.id, this.actions)
        .then(() => showNotification(201))
        .finally(() => (this.loading = false));
    },

    cleanOptionalDates() {
      if (!this.lastDateEnabled) {
        this.actions.last_date = null;
      }
      if (!this.nextDateEnabled) {
        this.actions.next_date = null;
      }
      if (!this.dueDateEnabled) {
        this.actions.due_date = null;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
div[class*="col-"]
  display: flex
  align-items: flex-end
  & > div
    width: 100%
</style>
