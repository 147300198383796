import axios from "@/plugins/Axios";
import { getQueryParams } from "@/shared/methods.vue";

const model = "marcos-legales-especificos";

function all(queryParams) {
  const params = getQueryParams(queryParams);
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}?${params}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function update(id, data) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, data);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function tools(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}/tools`);
}

export { all, remove, update, find, tools };
