const accionesCorrectivasFields = {
  "Autoridad reguladora": "autoridad_reguladora",
  "Marco legal general": "marco_legal_general_version.marco_legal_general.name",
  "Marco legal específico": "name",
  Requerimiento: "requerimiento",
  "Última fecha de realización": "pivot.last_date",
  "Próxima fecha": "pivot.next_date",
  "Acción correctiva": "pivot.action",
  "Responsable de la acción correctiva": "pivot.owner",
  "Status de la acción correctiva": "pivot.action_status",
  "Fecha de compromiso de cierre": "pivot.due_date",
};
const ambitoFields = {
  Ambito: "ambito",
  "Marco legal general": "marco_legal_general_version.marco_legal_general.name",
  "Marco legal específico": "name",
  Requerimiento: "requerimiento",
  Aspecto: "aspecto",
  "Link oficial": "link_oficial",
  "Autoridad reguladora": "autoridad_reguladora",
  "Tipo de verificacion": "verificacion",
  "¿Requiere programación de análisis, estudio y/o reporte?":
    "requerido_programacion_de_analisis_de_estudio_y_o_reporte",
  Cumple: "pivot.cumple",
};
const aspectoFields = {
  Aspecto: "aspecto",
  Ambito: "ambito",
  "Marco legal general": "marco_legal_general_version.marco_legal_general.name",
  "Marco legal específico": "name",
  Requerimiento: "requerimiento",
  "Link oficial": "link_oficial",
  "Autoridad reguladora": "autoridad_reguladora",
  "Tipo de verificacion": "verificacion",
  "¿Requiere programación de análisis, estudio y/o reporte?":
    "requerido_programacion_de_analisis_de_estudio_y_o_reporte",
  Cumple: "pivot.cumple",
};
const resumenFields = {
  "Marco legal general": "marco_legal_general_version.marco_legal_general.name",
  "Marco legal específico": "name",
  Requerimiento: "requerimiento",
  Aspecto: "aspecto",
  Ambito: "ambito",
  "Autoridad reguladora": "autoridad_reguladora",
  "Tipo de verificacion": "verificacion",
  Cumple: "pivot.cumple",
};
export {
  accionesCorrectivasFields,
  ambitoFields,
  aspectoFields,
  resumenFields,
};
